<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Uusi laina</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <loan-form submit-btn-text="Luo" @sendloan="submit($event)"></loan-form>
    </v-card>
  </div>
</template>

<script>
import LoanForm from "../../components/Loan/LoanForm";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";

export default {
  title: "Uusi laina",

  components: {
    LoanForm,
  },

  mixins: [mixins],

  methods: {
    async submit(loan) {
      try {
        await axiosMethods.post("/api/v1/loan/create", loan);
        this.$router.push("/loans");
        this.showPopup("Uusi laina luotu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
